<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import {
  createEstate,
  fetchEstates,
  updateEstate,
} from "@/services/land.service";
import EstateList from "@/views/pages/lands/estate-list";

import { required } from "vuelidate/lib/validators";

/**
 * Form validation component
 */

export default {
  page: {
    title: "CadLands Estates",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, EstateList },
  data() {
    return {
      title: "Estates",
      toggleForm: false,
      items: [],
      estate: {
        name: "",
        plans: [
          {
            name: "",
            price: "",
          },
        ],
      },
      estateList: [],
      fetchingEstates: false,
      submitted: false,
      submit: false,
      loading: false,
      errors: {},
      updatingEstate: false,
    };
  },
  validations: {
    estate: {
      name: { required },
    },
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    authUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted: async function () {
    this.fetchEstates();
  },
  methods: {
    addPlan() {
      this.estate.plans.push({ name: "", price: "" });
    },
    removePlan(index) {
      if (this.estate.plans.length == 1) return;
      this.estate.plans.splice(index, 1);
    },
    toggleCreateEsateField(state) {
      this.updatingEstate = false;
      // reset field (incase an update was initiated)
      this.estate = {
        name: "",
        plans: [
          {
            name: "",
            price: "",
          },
        ],
      };
      this.toggleForm = state;
    },
    editEstate(estate) {
      console.log(estate);
      if (!estate.plans) {
        this.estate.id = estate.id;
        this.estate.name = estate.name;
        this.estate.plans = [{ name: "", price: "" }];
      } else {
        this.estate = estate;
      }
      this.updatingEstate = true;
      this.toggleForm = true;
    },
    async createEstate() {
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        try {
          this.errors = {};
          this.loading = true;

          var response;

          if (this.updatingEstate) {
            console.log(this.estate);
            response = await updateEstate(this.estate);
            this.$store.dispatch("notification/success", response.data.message);

            var updatedEstate = response.data.data;
            // get estate index
            var index = this.estateList.findIndex(
              (estate) => estate.id == updatedEstate.id
            );
            this.estateList.splice(index, 1, updatedEstate); // replace with the updated estate
            this.updatingEstate = false;
          } else {
            response = await createEstate(this.estate);
            this.$store.dispatch("notification/success", response.data.message);
            this.estateList.unshift(response.data.data);
          }

          // reset input field
          this.estate = {
            name: "",
            plans: [
              {
                name: "",
                price: "",
              },
            ],
          };
          this.loading = false;
          this.submitted = false;
          this.$bvToast.toast(response.data.message, {
            title: "Successful!",
            variant: "success",
            solid: true,
          });
        } catch (error) {
          this.loading = false;
          console.log(error);
          switch (error.response.status) {
            case 422:
              console.log(error.response.data.errors);
              this.errors = error.response.data.errors;
              break;
            case 500:
              this.$bvToast.toast(error.response.data.message, {
                title: "Error!",
                variant: "danger",
                solid: true,
              });
              break;
            case 403:
              this.$bvToast.toast(error.response.data.message, {
                title: "Error!",
                variant: "danger",
                solid: true,
              });
              break;
            default:
              this.$bvToast.toast(
                "[" +
                  error.response.status +
                  "] Something went wrong. Please try again!",
                {
                  title: "Error!",
                  variant: "danger",
                  solid: true,
                }
              );
              break;
          }
        }
      }
    },
    async fetchEstates() {
      try {
        this.errors = {};
        this.fetchingEstates = true;
        var response = await fetchEstates();
        this.estateList = response.data.data;
        console.log(response.data);
        this.fetchingEstates = false;
      } catch (error) {
        this.fetchingEstates = false;
        console.log(error);
        switch (error.response.status) {
          case 500:
            this.$bvToast.toast(error.response.data.message, {
              title: "Error!",
              variant: "danger",
              solid: true,
            });
            break;
          case 403:
            this.$bvToast.toast(error.response.data.message, {
              title: "Error!",
              variant: "danger",
              solid: true,
            });
            break;
          default:
            this.$bvToast.toast("Something went wrong. Please try again!", {
              title: "Error!",
              variant: "danger",
              solid: true,
            });
            break;
        }
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row" v-if="toggleForm">
      <!-- end col -->
      <div class="col-md-1"></div>
      <div class="col-lg-10">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">
              {{ updatingEstate ? "Updating" : "Create" }} Estate
            </h4>
            <p class="card-title-desc">
              Fill the form below to
              {{ updatingEstate ? "updating" : "create" }} an Estate.
            </p>
            <form class="needs-validation" @submit.prevent="createEstate">
              <div class="row">
                <div class="col-md-11">
                  <div class="form-group position-relative">
                    <label for="validationTooltip12">Estate Name</label>
                    <input
                      id="validationTooltip12"
                      autofocus
                      v-model="estate.name"
                      type="text"
                      class="form-control"
                      placeholder="Estate Name"
                      :class="{
                        'is-invalid':
                          (submitted && $v.estate.name.$error) || errors.name,
                      }"
                    />
                    <div
                      v-if="(submitted && $v.estate.name.$error) || errors.name"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.estate.name.required"
                        >This field is required</span
                      >
                      <span v-if="errors.name"> {{ errors.name[0] }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- YOU CAN DUPLICATE FROM HERE -->
              <label for="validationTooltip12"
                >Estate Payment Plan{{
                  estate.plans.length > 1 ? "s" : ""
                }}</label
              >
              <div
                class="row"
                v-for="(plan, index) in estate.plans"
                :key="`plan-${index}`"
              >
                <!-- <div class="col-md-1"></div> -->
                <div class="col-md-5">
                  <div class="form-group position-relative">
                    <!-- <label for="validationTooltip12">Estate Payment Plan</label> -->
                    <input
                      id="validationTooltip12"
                      v-model="plan.name"
                      type="text"
                      class="form-control"
                      placeholder="e.g 6 Months installment"
                      required
                      :class="{
                        'is-invalid': errors.plans,
                      }"
                    />
                    <div v-if="errors.plans" class="invalid-feedback">
                      {{ errors.plans[0] }}
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group position-relative">
                    <!-- <label for="validationTooltip9">Estate Price</label> -->
                    <input
                      id="validationTooltip09"
                      v-model="plan.price"
                      type="number"
                      class="form-control"
                      placeholder="Estate Price"
                      required
                    />
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group position-relative">
                    <!-- <div style="height: 28px; width: 20px"></div> -->
                    <span
                      class="btn btn-primary"
                      @click="addPlan()"
                      type="submit"
                      style="margin-right: 5px"
                    >
                      + Add
                    </span>

                    <span
                      class="btn btn-danger"
                      type="submit"
                      @click="removePlan(index)"
                    >
                      x
                    </span>
                  </div>
                </div>
              </div>
              <!-- DUPLICATE STOPS HERE -->

              <button class="btn btn-primary" type="submit">
                {{ updatingEstate ? "Update" : "Create" }} Estate
                <b-spinner
                  v-show="loading"
                  small
                  class="align-middle"
                ></b-spinner>
              </button>
              &nbsp;
              <button
                class="btn btn-danger btn-sm pull-right"
                @click="toggleCreateEsateField(false)"
              >
                Hide this form
              </button>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>

      <!-- end col -->
    </div>
    <!-- end row -->

    <div class="col-lg-12">
      <EstateList
        :estates="estateList"
        :fetchingEstates="fetchingEstates"
        @editEstate="editEstate"
        @toggleCreateEsateField="toggleCreateEsateField"
      />
      <!-- <Indirect /> -->
    </div>
  </Layout>
</template>