<script>
import { deleteEstate } from "@/services/land.service";
export default {
  props: {
    estates: {
      type: Array,
      default: () => {
        return [];
      },
    },
    fetchingEstates: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {
      processing: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: true,
      fields: [
        "#",
        { key: "name", sortable: true, label: "Estate Name" },
        { key: "plans", sortable: true, label: "Estate Plan" },
        "action",
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.estates.length;
    },
    isBusy() {
      return this.fetchingEstates;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.estates.length;
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    editEstate(data) {
      window.scrollTo(0, 0);
      this.$emit("editEstate", data.item);
    },
    async deleteEstate(data) {
      if (!confirm("Are you sure you want to delete this estate?\nYou cannot undo this action")) {
        return;
      }

      try {
        this.processing = true;
        await deleteEstate(data.item.id);
        this.estates.splice(
          this.estates.findIndex((estate) => estate.id === data.item.id),
          1
        );
        this.processing = false;
      } catch (error) {
        this.processing = false;
        console.log(error);
        switch (error.response.status) {
          case 500:
            this.$bvToast.toast(error.response.data.message, {
              title: "Error!",
              variant: "danger",
              solid: true,
            });
            break;
          case 403 || 401:
            this.$bvToast.toast(error.response.data.message, {
              title: "Error!",
              variant: "danger",
              solid: true,
            });
            break;
          default:
            this.$bvToast.toast(
              "[" +
                error.response.status +
                "] Something went wrong. Please try again!",
              {
                title: "Error!",
                variant: "danger",
                solid: true,
              }
            );
            break;
        }
      }
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <!-- <h4 class="card-title mb-4">All Estates and Plans <small class="btn btn-primary btn-sm">+ Add Estate</small></h4> -->
      <!-- <h4 class="card-title mb-4">All Estates and Plans <small class="btn btn-primary btn-sm">+ Add Estate</small></h4> -->
      <div class="row mt-4">
        <div class="col-sm-12 col-md-6">
          <div class="dataTables_length">
            <h4 class="card-title">All Estates and Plans</h4>
          </div>
        </div>
        <!-- Search -->
        <div class="col-sm-12 col-md-6">
          <div class="dataTables_filter text-md-right">
            <button class="btn btn-primary btn-sm" @click="$emit('toggleCreateEsateField', true); window.scrollTo(0, 0);">+ Add Estate</button>
          </div>
        </div>
        <!-- End search -->
      </div>
      <div class="row mt-4">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              Show&nbsp;
              <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
            </label>
          </div>
        </div>
        <!-- Search -->
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_filter" class="dataTables_filter text-md-right">
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input
                v-model="filter"
                type="search"
                class="form-control form-control-sm ml-2"
              ></b-form-input>
            </label>
          </div>
        </div>
        <!-- End search -->
      </div>
      <b-overlay :show="processing" rounded="md" opacity="0.8">
      <div class="table-responsive">
        <b-table
          :items="estates"
          :fields="fields"
          responsive="sm"
          :per-page="perPage"
          :busy="isBusy"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          show-empty
        >
        <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong> &nbsp; Loading...</strong>
            </div>
          </template>
          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(plans)="data">
            <ul v-if="data.item != null">
              <li v-for="(plan, index) in data.item.plans" :key="index">
                {{ plan.name }} @ {{ plan.price | currencyFormat }}
              </li>
            </ul>
          </template>
          <template v-slot:cell(action)="data">
            <a
              href="javascript:void(0);"
              class="mr-3 text-primary"
              v-b-tooltip.hover
              data-toggle="tooltip"
              title="Edit"
              @click="editEstate(data)"
            >
              <i class="mdi mdi-pencil font-size-18"></i>
            </a>
            <a href="javascript:void(0);" @click="deleteEstate(data)" class="text-danger" v-b-tooltip.hover title="Delete">
              <i class="mdi mdi-trash-can font-size-18"></i>
            </a>
          </template>
        </b-table>
      </div>
      </b-overlay>
      <div class="row">
        <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>