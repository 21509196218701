import { http } from "./http.service";

export function purchaseRequest(purchase) {
    return http().post("/purchases", purchase);
}

export function getPurchaseRequests() {
    return http().get("/purchases");
}

export function recordPayment (payment) {
    return http().post('/payments', payment);
}

export function createEstate (estate) {
    return http().post('/estates', estate);
}

export function updateEstate (estate) {
    return http().put('/estates/' + estate.id, estate);
}

export function deleteEstate (estateId) {
    return http().delete('/estates/' + estateId);
}

export function fetchEstates () {
    return http().get('/estates');
}